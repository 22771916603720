import { MenuOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Grid, Menu, Row } from "antd";
import classnames from "classnames/bind";
import Image from "next/image";
import Link from "next/link";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { LanguageSelector } from "@/components/Language/LanguageSelector";
import LoginLockButton from "@/components/Login/LoginLockButton";

import { useScroll } from "@/hooks/useScroll";
import { useAuth } from "@/lib/AuthContext";
import { withApollo } from "@/lib/initApollo";
import { useMixpanel } from "@/lib/MixpanelContext";
import Auth from "@/lib/auth";

import styles from "./Header.module.css";

const cx = classnames.bind(styles);

const { useBreakpoint } = Grid;

const SMALL_HEADER_SCROLL = -300;

const LANDING_MENU_ITEMS = [
  { name: "aboutUs", href: "/aboutUs" },
  { name: "blog", href: "https://blog.klog.co" },
  { name: "careers", href: "/careers" },
  { name: "warehousing", href: "/warehousing" },
  { name: "contactUs", href: "/contactUs" },
  { name: "services", href: "/services" },
];

const LandingHeader: React.FunctionComponent = () => {
  const { authenticating, user, setUser } = useAuth();
  const { mixpanel } = useMixpanel();
  const dropdownRef = useRef();
  const { xl } = useBreakpoint();
  const [smallHeader, setSmallHeader] = useState(false);
  useScroll(({ currPos: { y } }) => {
    setSmallHeader(y <= SMALL_HEADER_SCROLL);
  }, []);
  const menuTest = () => (
    <Menu mode="horizontal">
      {LANDING_MENU_ITEMS.map(({ href, name }) => (
        <Menu.Item key={name}>
          <Link prefetch={false} href={href}>
            <a className={styles.link}>
              <FormattedMessage id={name} />
            </a>
          </Link>
        </Menu.Item>
      ))}
      <LanguageSelector open />
      <Menu.Item key="login">
        <LoginLockButton
          buttonProps={{ className: styles.login }}
          loading={authenticating}
          buttonType="link"
          showedInLanding
          redirect
        />
      </Menu.Item>
      {user && (
        <Menu.Item key="logout">
          <Button
            type="text"
            onClick={() => {
              mixpanel.reset();
              Auth.lockLogout(setUser);
            }}
          >
            <FormattedMessage id="logout" />
          </Button>
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      <div className={styles.spacer} />
      <Row
        className={cx({ smallHeader, header: true })}
        justify="center"
        align="middle"
      >
        <Col span={24}>
          <Row
            className={styles.innerFooter}
            justify="space-between"
            align="middle"
          >
            <Col>
              <Link href="/">
                <a className={styles.logoWrapper}>
                  <Image
                    layout="fixed"
                    width={148}
                    height={38}
                    priority
                    src="/static/images/logos/logo-klog.svg"
                    alt="KLog.co"
                    className={styles.headerLogo}
                  />
                  <span className={styles.headerLogoSquared}>
                    <Image
                      layout="fixed"
                      width={37}
                      height={38}
                      src="/static/images/logos/isotipo-klog.svg"
                      alt="KLog.co"
                    />
                  </span>
                </a>
              </Link>
            </Col>
            <Col xl={0} xs={2} ref={dropdownRef}>
              <Dropdown
                overlay={menuTest()}
                trigger={["click"]}
                getPopupContainer={() => dropdownRef.current}
              >
                <Button icon={<MenuOutlined />} />
              </Dropdown>
            </Col>
            <Col xl={20} xs={0}>
              <Row align="middle" gutter={xl ? 12 : 0} justify="end">
                <Col>
                  <Row gutter={xl ? 35 : 24}>
                    {LANDING_MENU_ITEMS.map(({ href, name }) => (
                      <Col key={name}>
                        <Link prefetch={false} href={href}>
                          <a className={styles.link}>
                            <FormattedMessage id={name} />
                          </a>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col xxl={{ offset: 2 }} xs={{ offset: 0 }}>
                  <LoginLockButton
                    buttonProps={{ className: styles.login }}
                    loading={authenticating}
                    buttonType="link"
                    showedInLanding
                    redirect
                  />
                  {user && (
                    <Button
                      type="text"
                      onClick={() => {
                        mixpanel.reset();
                        Auth.lockLogout(setUser);
                      }}
                    >
                      <FormattedMessage id="logout" />
                    </Button>
                  )}
                  <LanguageSelector />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default withApollo()(LandingHeader);
