import { Col, Row } from "antd";
import classnames from "classnames/bind";
import Image from "next/image";
import React from "react";
import { FormattedMessage } from "react-intl";

import { useRotateKeys } from "../../Hooks/hooks";

import styles from "./SubHero.module.css";

const cx = classnames.bind(styles);

const RESERVATION = "reservation";
const MANAGE = "manage";
const OPTIMIZE = "optimize";
const KEYS = [RESERVATION, MANAGE, OPTIMIZE];

const SubHero = () => {
  const [currentKey] = useRotateKeys(KEYS);
  return (
    <div className={styles.subHero}>
      <Row align="middle" justify="center">
        <Col xl={10} md={24}>
          <Row gutter={24}>
            <Col span={24}>
              <h4>
                <FormattedMessage id="subHeroSubtitle" />
              </h4>
              <h2>
                <FormattedMessage id="subHeroTitle" />
              </h2>
            </Col>
            {KEYS.map((key) => (
              <Col key={key} xl={24} md={8} xs={24}>
                <div className={cx({ item: true, active: currentKey === key })}>
                  <h3>
                    <FormattedMessage id={key} />
                  </h3>
                  <p>
                    <FormattedMessage id={`${key}Description`} />
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xl={14}>
          <div className={styles.layers}>
            <div
              className={cx({
                active: currentKey === OPTIMIZE,
              })}
            >
              <Image
                alt="ship optimal travel"
                layout="intrinsic"
                width={793}
                height={558}
                src="/static/index/intelLayer.png"
              />
            </div>
            <div className={styles.base}>
              <Image
                alt="shipment on a line"
                layout="intrinsic"
                width={793}
                height={558}
                src="/static/index/shipLayer.png"
                priority
              />
            </div>
            <div
              className={cx({
                active: currentKey === MANAGE,
              })}
            >
              <Image
                alt="comments about plataform"
                layout="intrinsic"
                width={793}
                height={558}
                src="/static/index/controlLayer.png"
              />
            </div>
            <div
              className={cx({
                active: currentKey === RESERVATION,
              })}
            >
              <Image
                alt="moving load"
                layout="intrinsic"
                width={793}
                height={558}
                src="/static/index/moveLayer.png"
                priority
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SubHero;
