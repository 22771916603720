import { Layout } from "antd";
import Head from "next/head";
import React from "react";
import { useIntl } from "react-intl";

import Footer from "../LandingPage/Footer";
import Header from "../LandingPage/Header";

export const WebLayout: React.FunctionComponent = ({ children }) => {
  const intl = useIntl();
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          name="description"
          content={intl.formatMessage({ id: "siteDescription" })}
        />
      </Head>
      <Layout style={{ background: "#f0f2f5" }}>
        <Header />
        {children}
        <Footer />
      </Layout>
    </>
  );
};

const BlankLayout: React.FunctionComponent = ({ children }) => {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Layout style={{ background: "#f0f2f5" }}>{children}</Layout>
    </>
  );
};

export const getBlankLayout = (page) => <BlankLayout>{page}</BlankLayout>;
export const getLayout = (page) => <WebLayout>{page}</WebLayout>;
