import React, { useEffect, useRef } from "react";
import { Skeleton } from "antd";
import dynamic from "next/dynamic";
import { FormattedMessage, useIntl } from "react-intl";
import Typed from "typed.js";

import styles from "./Hero.module.scss";
import { PromoBanner } from "../Quoting/PromoBanner";

const CargoDetails = dynamic(
  () =>
    import("../Quoting/Searcher/CargoDetails/CargoDetails").then((mod) => mod),
  { loading: () => <Skeleton active />, ssr: false }
);

type Props = {};

export const LandingHero: React.FunctionComponent<Props> = () => {
  const el = useRef(null);
  const typed = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    const options = {
      strings: [
        intl.formatMessage({ id: "fast" }),
        intl.formatMessage({ id: "clicks" }),
        intl.formatMessage({ id: "digital" }),
      ],
      typeSpeed: 56,
      showCursor: true,
      loop: true,
      backSpeed: 30,
    };
    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, [intl]);

  return (
    <div className={styles.hero}>
      <video
        src="/static/videos/backgroundVideo.mp4"
        poster="/static/index/bg.jpg"
        autoPlay
        muted
        loop
        playsInline
      />
      <div className={styles.content}>
        <div>
          <h1 className={styles.title}>
            <FormattedMessage id="welcomeTitle" />
          </h1>
          <h4 className={styles.titleAnimate}>
            <span ref={el} />
          </h4>
        </div>
        <div className={styles.quotingContainer}>
          <PromoBanner />
          <CargoDetails />
        </div>
      </div>
    </div>
  );
};
