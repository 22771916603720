import { Button, Col, Row, Space } from "antd";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./ContactButtons.module.css";

export const ContactButtons = () => {
  return (
    <Row justify="center" gutter={[24, 36]}>
      <Col md={24}>
        <Space direction="vertical" align="center" className={styles.card}>
          <div className={styles.cardContent}>
            <h3 className={styles.title}>
              <FormattedMessage id="contactUsTitle" />
            </h3>
            <p className={styles.content}>
              <FormattedMessage id="contactUsDescription" />
            </p>
            <Link href="/contactUs">
              <Button danger size="large" className={styles.orangeButton}>
                <FormattedMessage id="contactUs" />
              </Button>
            </Link>
          </div>
        </Space>
      </Col>
    </Row>
  );
};
