import React from "react";

import { ContactButtons } from "../components/Blog/ContactButtons/ContactButtons";
import { AboutUsIntro } from "../components/LandingPage/AboutUsIntro";
// import ContactFormModal from "../components/LandingPage/ContactFormModal";
import { LandingHero } from "../components/LandingPage/Hero";
import { LandingBodyArticle } from "../components/LandingPage/LandingBodyArticle";
import { Section } from "../components/LandingPage/Section";
import SubHero from "../components/LandingPage/SubHero";
import { getLayout } from "../components/Layouts/WebLayout";

import { withApollo } from "@/lib/initApollo";
import { PageFunctionComponent } from "@/types/pages";

import styles from "./index.module.css";

const Index: PageFunctionComponent = () => {
  return (
    <>
      <LandingHero />
      <Section className={styles.lightSection}>
        <SubHero />
      </Section>
      <Section>
        <LandingBodyArticle />
      </Section>
      <Section className={styles.lightSection}>
        <ContactButtons />
        <AboutUsIntro />
      </Section>
    </>
  );
};

const WrappedIndex = withApollo()(Index) as PageFunctionComponent;
WrappedIndex.getLayout = getLayout;

export default WrappedIndex;
