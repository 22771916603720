import { Col, Row, Space } from "antd";
import classnames from "classnames/bind";
import Image from "next/image";
import React, { useRef } from "react";
import { strongFormatter } from "src/common/formatters";
import { FormattedMessage } from "react-intl";

import { useOnScreen } from "../../Hooks/hooks";

import styles from "./LandingBodyArticle.module.css";

const cx = classnames.bind(styles);

export const LandingBodyArticle = () => {
  const commsRef = useRef();
  const isCommsVisible = useOnScreen(commsRef, "150px");
  const intelRef = useRef();
  const isIntelVisible = useOnScreen(intelRef, "150px");
  const trackRef = useRef();
  const isTrackVisible = useOnScreen(trackRef, "150px");
  return (
    <div className={styles.landingBody}>
      <div className={styles.imageContainer}>
        <Row gutter={[24, 24]} justify="center" align="middle">
          <Col lg={10}>
            <Space size={32} direction="vertical">
              <h2>
                <FormattedMessage id="whatWhenWhere" />
              </h2>
              <p>
                <FormattedMessage
                  id="whatWhenWhereDescription1"
                  values={{ strong: strongFormatter }}
                />
              </p>
              <p>
                <FormattedMessage id="whatWhenWhereDescription2" />
              </p>
            </Space>
          </Col>
          <Col lg={14}>
            <Image
              alt="klog.co plataform illustration"
              priority
              src="/static/index/track.png"
              layout="intrinsic"
              width={1814}
              height={1290}
            />
            <div
              className={cx({
                floatingTrack: true,
                animated: true,
                visible: isTrackVisible,
              })}
            >
              <Image
                alt="data about plataform"
                src="/static/index/trackHint.png"
                layout="intrinsic"
                width={610}
                height={628}
              />
            </div>
            <div
              className={cx({
                floatingSku: true,
                animated: true,
                visible: isTrackVisible,
              })}
            >
              <Image
                alt="table with shipment info"
                src="/static/index/skuHint.png"
                layout="intrinsic"
                width={1262}
                height={448}
              />
            </div>
            <div ref={trackRef} />
          </Col>
        </Row>
      </div>
      <div className={styles.imageContainer}>
        <Row gutter={[24, 24]} justify="center" align="middle">
          <Col lg={{ span: 14, order: 0 }} xs={{ order: 1 }}>
            <Image
              priority
              alt="klog.co plataform illustration"
              src="/static/index/intel.png"
              layout="intrinsic"
              width={1814}
              height={1290}
            />
            <div
              className={cx({
                floatingContainer: true,
                animated: true,
                visible: isIntelVisible,
              })}
            >
              <Image
                alt="container loading"
                src="/static/index/containerHint.png"
                layout="intrinsic"
                width={454}
                height={260}
              />
            </div>
            <div
              className={cx({
                floatingSupplier: true,
                animated: true,
                visible: isIntelVisible,
              })}
            >
              <Image
                alt="provider info"
                src="/static/index/supplierHint.png"
                layout="intrinsic"
                width={992}
                height={456}
              />
            </div>
            <div ref={intelRef} />
          </Col>
          <Col lg={10} xs={{ order: 0 }}>
            <Space size={32} direction="vertical">
              <h2>
                <FormattedMessage id="intelligent" />
              </h2>
              <p>
                <FormattedMessage
                  id="intelligentDescription1"
                  values={{ strong: strongFormatter }}
                />
              </p>
              <p>
                <FormattedMessage id="intelligentDescription2" />
              </p>
            </Space>
          </Col>
        </Row>
      </div>
      <div className={styles.imageContainer}>
        <Row gutter={[24, 24]} justify="center" align="middle">
          <Col lg={10}>
            <Space size={32} direction="vertical">
              <h2>
                <FormattedMessage id="logisticsAndEmails" />
              </h2>
              <p>
                <FormattedMessage id="logisticsAndEmailsDescription1" />
              </p>
              <p>
                <FormattedMessage
                  id="logisticsAndEmailsDescription2"
                  values={{ strong: strongFormatter }}
                />
              </p>
            </Space>
          </Col>
          <Col lg={14}>
            <Image
              alt="klog.co plataform illustration"
              priority
              src="/static/index/comms.png"
              layout="intrinsic"
              width={1814}
              height={1290}
            />
            <div
              className={cx({
                floatingDocs: true,
                animated: true,
                visible: isCommsVisible,
              })}
            >
              <Image
                alt="automate documents"
                src="/static/index/docHint.png"
                layout="intrinsic"
                width={530}
                height={446}
              />
            </div>
            <div
              className={cx({
                floatingChat: true,
                animated: true,
                visible: isCommsVisible,
              })}
            >
              <Image
                alt="chat"
                src="/static/index/chatHint.png"
                layout="intrinsic"
                width={830}
                height={446}
              />
            </div>
            <div ref={commsRef} />
          </Col>
        </Row>
      </div>
      <Row gutter={[24, 24]} justify="center" align="middle">
        <Col lg={{ span: 14, order: 0 }} xs={{ order: 1 }}>
          <Image
            priority
            alt="three pictures with images of people"
            src="/static/index/people.jpg"
            layout="responsive"
            width={1566}
            height={1002}
          />
        </Col>
        <Col lg={10} xs={{ order: 0 }}>
          <Space size={32} direction="vertical">
            <h2>
              <FormattedMessage id="weMoveCargo" />
            </h2>
            <p>
              <FormattedMessage id="weMoveCargoDescription1" />
            </p>
            <p>
              <FormattedMessage id="weMoveCargoDescription2" />
            </p>
          </Space>
        </Col>
      </Row>
      <Row gutter={48} className={styles.whatWillYouNotice}>
        <Col lg={6}>
          <h2>
            <FormattedMessage id="whatWillYouNotice" />
          </h2>
        </Col>
        <Col lg={18}>
          <Row gutter={[24, 48]}>
            <Col lg={8} md={12}>
              <h3>
                <FormattedMessage id="order" />
              </h3>
              <p>
                <FormattedMessage id="orderDescription" />
              </p>
            </Col>
            <Col lg={8} md={12}>
              <h3>
                <FormattedMessage id="control" />
              </h3>
              <p>
                <FormattedMessage id="controlDescription" />
              </p>
            </Col>
            <Col lg={8} md={12}>
              <h3>
                <FormattedMessage id="transparency" />
              </h3>
              <p>
                <FormattedMessage id="transparencyDescription" />
              </p>
            </Col>
            <Col lg={8} md={12}>
              <h3>
                <FormattedMessage id="intelligence" />
              </h3>
              <p>
                <FormattedMessage id="intelligenceDescription" />
              </p>
            </Col>
            <Col lg={8} md={12}>
              <h3>
                <FormattedMessage id="signal" />
              </h3>
              <p>
                <FormattedMessage id="signalDescription" />
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
