import { gql, useQuery } from "@apollo/client";
import Image from "next/image";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Card, Gradient } from "../Klog";

import styles from "./PromoBanner.module.scss";

const IS_PROMO_ACTIVE = gql`
  query isPromoActive {
    isPromoActive
  }
`;

export const PromoBanner: React.FunctionComponent = () => {
  const { data: { isPromoActive } = {}, loading } = useQuery(IS_PROMO_ACTIVE);
  return !loading && isPromoActive ? (
    <div className={styles.container}>
      <Gradient>
        <Card className={styles.card} bodyStyle={{ padding: 12 }}>
          <h4 className={styles.title}>
            <FormattedMessage id="uniquePromo" />
          </h4>
          <div>
            <Image
              alt="promo"
              width="135"
              height="27"
              src="/static/images/common/common-promo_banner.png"
            />
          </div>
          <div className={styles.note}>
            <FormattedMessage id="promoLimit" />
          </div>
        </Card>
      </Gradient>
      <a
        href="https://landing.klog.co/t%C3%A9rminos-y-condiciones-book-and-pay"
        target="_blank"
        className={styles.link}
        rel="noopener noreferrer"
      >
        <FormattedMessage id="seeTermsAndConditions" />
      </a>
    </div>
  ) : null;
};
