import { GlobalOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import classNames from "classnames/bind";
import React, { useState } from "react";

import Auth from "@/lib/auth";
import { useAuth } from "../../lib/AuthContext";
import { LANGUAGES, useLanguage } from "./LanguageContext";

import styles from "./LanguageSelector.module.css";

type Props = { open?: boolean };

const cx = classNames.bind(styles);

const { Item: MenuItem } = Menu;

export const LanguageSelector: React.FunctionComponent<Props> = ({
  open = false,
}) => {
  const { user, setUser } = useAuth();
  const { language: selectedLanguage, setLanguage } = useLanguage();
  const [loading, setLoading] = useState(false);
  const handleClick = async (language) => {
    setLanguage(language);
    if (user) {
      setLoading(true);
      await Auth.updateMetadata(
        {
          language,
        },
        setUser
      );
      setLoading(false);
    }
  };
  const menu = (
    <Menu>
      {Object.values(LANGUAGES).map((language) => (
        <MenuItem key={language}>
          <Button
            key={language}
            className={cx({
              loading,
              languageSelector: true,
              selected: language === selectedLanguage,
            })}
            onClick={() => !loading && handleClick(language)}
            type="link"
          >
            {language}
          </Button>
        </MenuItem>
      ))}
    </Menu>
  );
  return open ? (
    menu
  ) : (
    <Dropdown overlay={menu} disabled={loading}>
      <GlobalOutlined className={cx({ loading, icon: true })} />
    </Dropdown>
  );
};
