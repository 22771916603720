import { Col, Row, Space } from "antd";
import Image from "next/image";
import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./AboutUsIntro.module.css";

export const AboutUsIntro = () => {
  return (
    <Row className={styles.greatTeam} gutter={[0, 60]} justify="center">
      <Col span={24}>
        <Image
          alt="team working"
          src="/static/index/people2.jpg"
          layout="intrinsic"
          width={2828}
          height={972}
        />
      </Col>
      <Col span={18}>
        <Space direction="vertical" size={36}>
          <h2>
            <FormattedMessage id="greatTeam" />
          </h2>
          <p className={styles.highlight}>
            <FormattedMessage id="greatTeamDescription" />
          </p>
        </Space>
      </Col>
    </Row>
  );
};
